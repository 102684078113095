import { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Call, DarkModeOutlined, Folder, LightModeOutlined, Newspaper } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../ThemeContext';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../logo.png';

const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'darkMode',
})(({ theme, darkMode }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: darkMode ? theme.palette.grey[900] : '#1e2459',
  boxShadow: theme.shadows[1],
}));

export default function AppAppBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { darkMode, toggleTheme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const [openDonate, setOpenDonate] = useState(false);

  const isActive = (path) => location.pathname === path;

  return (
    <Box sx={{ bgcolor: darkMode ? 'grey.900' : 'white' }}>
      <AppBar
        position="fixed"
        sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', width: '100%' }}
      >
        <StyledToolbar darkMode={darkMode} variant="dense" disableGutters sx={{ px: 1 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} gap={isMobile ? 1 : 2}>
            <Button
              variant="text"
              color="white"
              size="small"
              onClick={() => navigate('/')}
              sx={{
                bgcolor: isActive('/') ? 'rgba(255,255,255,0.2)' : 'transparent',
                borderRadius: '12px',
                paddingX: !isMobile ? 2 : '' 
              }}
            >
              {isMobile ? <img src={logo} alt="logo" style={{ width: 24, height: 24 }} /> : 'Home'}
            </Button>

            <Button
              variant="text"
              color="white"
              size="small"
              onClick={() => navigate('/problems')}
              sx={{
                bgcolor: isActive('/problems') ? 'rgba(255,255,255,0.2)' : 'transparent',
                borderRadius: '12px',
                paddingX: !isMobile ? 2 : '' 
              }}
            >
              {isMobile ? <Folder /> : 'Problems'}
            </Button>

            <Button
              variant="text"
              color="white"
              size="small"
              onClick={() => navigate('/book-session')}
              sx={{
                bgcolor: isActive('/book-session') ? 'rgba(255,255,255,0.2)' : 'transparent',
                borderRadius: '12px',
                paddingX: !isMobile ? 2 : '' 
              }}
            >
              {isMobile ? <Call /> : 'Book Session'}
            </Button>

            <Button
              variant="text"
              color="white"
              size="small"
              onClick={() => navigate('/newsletter')}
              sx={{
                bgcolor: isActive('/newsletter') ? 'rgba(255,255,255,0.2)' : 'transparent',
                borderRadius: '12px',
                paddingX: !isMobile ? 2 : '' 
              }}
            >
              {isMobile ? <Newspaper /> : 'NewsLetter'}
            </Button>
          </Box>

          <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
            {darkMode ? <LightModeOutlined /> : <DarkModeOutlined />}
          </IconButton>
        </StyledToolbar>
      </AppBar>
    </Box>
  );
}
