import supabase from '../supabase';
import { Backdrop, Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Collapse, Container, Divider, FormControlLabel, FormGroup, Grid2, IconButton, MenuItem, Radio, Select, Tooltip, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ArticleOutlined, ExpandLess, ExpandMore, RestartAlt, VideoFileOutlined } from '@mui/icons-material';
import SimilarQuestionCard from '../components/similar-card';
import { useMediaQuery, useTheme } from '@mui/material';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ThemeContext } from "../ThemeContext";
import Toast from '../components/Toast';
import { useNavigate, useParams } from 'react-router-dom';


const LcProblemDetails = () => {
  const { darkMode } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const { id } = useParams(); 
  const [problemData, setProblemData] = useState(null);
  const [similarProblems, setSimilarProblems] = useState([]);
  const [similarProblemsCopy, setSimilarProblemsCopy] = useState([]);
  const [lastState, setLastState] = useState([]);
  const [expanded, setExpanded] = useState(isMobile);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedDifficulty, setSelectedDifficulty] = useState('All'); 
  const [sortBySimilarityScore, setSortBySimilarityScore] = useState(true);
  const [sortByTutorial, setSortByTutorial] = useState(false);
  const [sortByAcceptanceRate, setSortByAcceptanceRate] = useState(false);
  const [sortByPopularity, setSortByPopularity] = useState(false);

  const [toast, setToast] = useState('');

  useEffect(() => {
    setExpanded(!isMobile);
  }, [isMobile]);



  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [visibleCount, setVisibleCount] = useState(25); 

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 25);
  };

  const parseNumber = (value) => {
    if (typeof value === 'number') return value;
  
    let num = parseFloat(value);
    
    if (value.includes('K')) {
      return num * 1000;
    } else if (value.includes('M')) {
      return num * 1000000;
    } else if (value.includes('B')) {
      return num * 1000000000;
    }
  
    return num;
  };  


  const handleResetFilters = () => {
    setSelectedDifficulty('All');
    setSortByAcceptanceRate(false);
    setSortByPopularity(false);
    setSortBySimilarityScore(true);
    setSortByTutorial(false);
    setSimilarProblems(similarProblemsCopy);
  };
  const handleSortByDifficulty = (difficulty) => {
    if (difficulty !== 'All') {
      let filteredProblems = similarProblemsCopy;
      filteredProblems = filteredProblems.filter(
        (problem) => problem.difficulty === difficulty
      );
      setSimilarProblems(filteredProblems);
    } else {
      setSimilarProblems(similarProblemsCopy);
    }
  };

  const handleSortByAcceptanceRate = () => {
    let filteredProblems = similarProblems;
    filteredProblems = [...filteredProblems].sort((a, b) => parseNumber(b.acceptance_rate) - parseNumber(a.acceptance_rate));
    setSimilarProblems(filteredProblems);
  };

  const handleSortByPopularity = () => {
    const sortedProblems = [...similarProblems].sort((a, b) => 
      (parseNumber(b.discussions) + parseNumber(b.likes)) - 
      (parseNumber(a.discussions) + parseNumber(a.likes))
    );
    setSimilarProblems(sortedProblems);
  };


  const handleSortBySimilarityScore = () => {
    let filteredProblems = similarProblems;
    filteredProblems = [...filteredProblems].sort((a, b) => parseNumber(b.score) - parseNumber(a.score));
    setSimilarProblems(filteredProblems);
  };

  const handleSortByTutorialAvailability = () => {
    setSortByTutorial((prevState) => {
      if (!prevState) {
        setLastState(similarProblems);
        const filteredProblems = similarProblems.filter(problem => problem.tutorial !== null);
        setSimilarProblems(filteredProblems);
      } else {
        setSimilarProblems(lastState);
      }
      return !prevState;
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: problemData, error: fetchError } = await supabase
          .from('problems')
          .select("problem_id, title, difficulty, url, tutorial, similar_problems")
          .eq("problem_id", id)
          .single();
  
        if (fetchError) {
          console.error("Error fetching problem data:", fetchError);
          setIsLoading(false);
          return;
        }
  
        setProblemData(problemData);
  
        const similarProblemsStr = problemData.similar_problems;
        if (similarProblemsStr && similarProblemsStr.length > 0) {
          const cleanedStr = similarProblemsStr.replace(/^\[|\]$/g, '');
          const pairs = cleanedStr.split(/\), \(/).map(pair => pair.replace(/[\(\)]/g, ''));
          const similarProblemsArray = pairs.map(pair => {
            const [problem_id, score] = pair.split(',').map(val => val.trim());
            return { id: parseInt(problem_id, 10), score: parseFloat(score) };
          });
  
          const similarProblemsMap = {};

          similarProblemsArray.forEach(({ id, score }) => {
            similarProblemsMap[id] = score;
          });
          

  
          const { data: similarProblemsData, error: similarProblemsError } = await supabase
            .from('problems')
            .select('title, problem_id, url, difficulty, acceptance_rate, discussions, likes, tutorial')
            .in('problem_id', Object.keys(similarProblemsMap));
  
          if (similarProblemsError) {
            setIsLoading(false);
            return;
          }

  
          if (similarProblemsData && similarProblemsData.length > 0) {
            const arr = similarProblemsData.map((problem, index) => {
              return {
                problem_id: problem.problem_id,
                title: problem.title,
                url: problem.url,
                difficulty: problem.difficulty,
                score: similarProblemsMap[problem.problem_id],
                acceptance_rate: problem.acceptance_rate,
                accepted: problem.accepted,
                discussions: problem.discussions,
                likes: problem.likes,
                tutorial: problem.tutorial,
              };
            });

            arr.sort((a, b) => b.score - a.score);
  
            setSimilarProblems(arr);
            setSimilarProblemsCopy(arr);
          } else {
            console.log("No similar problems found.");
          }
        } else {
          console.log("No similar problems listed.");
        }
  
        setIsLoading(false); // Ensure loading state is disabled in all cases
  
      } catch (error) {
        console.error("An error occurred:", error.message);
        setIsLoading(false);
      }
    }
  
    fetchData();
  }, [id]);
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: darkMode ? 'grey.900' : '',
      }}
    >

      {toast && <Toast msg={toast} onClose={() => setToast('')}/>}

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppAppBar/>

      {!isLoading &&
         <Container sx={{ flexGrow: 1 }}>
          <Card
            sx={{
              mt: 10,
              p: 3,
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              alignItems: { sm: 'center' },
              boxShadow: darkMode
                ? '0 8px 16px rgba(0, 0, 0, 0.6)' // More prominent shadow for dark mode
                : '0 4px 8px rgba(0, 123, 255, 0.2)', 
              borderRadius: 2,
              backgroundColor: darkMode 
                ? 'rgba(30, 30, 30, 0.8)' // Dark transparent background for dark mode
                : 'rgba(255, 255, 255, 0.2)',
            }}
          >
            <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: isMobile ? '12px' : '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor:
                      problemData.difficulty === 'Easy'
                        ? '#28a745'
                        : problemData.difficulty === 'Medium'
                        ? '#ffc107'
                        : '#dc3545',                    
                  }}
                />
                <Typography sx={{ ml: 1.5, textAlign: 'center', flexGrow: 1, color: darkMode ? '#e0e0e0' : '#333',}}>{problemData.problem_id + ". " + problemData.title}</Typography>
                
              </Box>

          
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: { xs: 'center', sm: 'flex-end' }, // Center on mobile, right-aligned on desktop
                mt: { xs: 2, sm: 0 }, // Add margin on mobile
                flexDirection: { xs: 'row', sm: 'row' },
              }}
            >
              <IconButton sx={{ color: darkMode ? '#e0e0e0' : '#007bff' }} onClick={() => window.open(problemData.url, '_blank')}>
                <OpenInNewIcon />
              </IconButton>
              <IconButton sx={{ color: darkMode ? '#e0e0e0' : '#007bff' }} onClick={() => !problemData.tutorial || problemData.tutorial['article'] === "" ? setToast('solution article not available yet') : window.open(problemData.tutorial['article'], '_blank')}>
                <ArticleOutlined />
              </IconButton>
              <IconButton sx={{ color: darkMode ? '#e0e0e0' : '#007bff' }} onClick={() => !problemData.tutorial || problemData.tutorial['video'] === "" ? setToast('video solution not available yet') : window.open(problemData.tutorial['video'], '_blank')}>
                <VideoFileOutlined />
              </IconButton>
            </Box>
          </Card>




          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginTop: 3 }} rowGap={2} >

            <Box sx={{ flexBasis: isMobile ? '100%' : '30%' }}>
              <Card
                sx={{
                  margin: 1,
                  backgroundColor: darkMode 
                    ? 'rgba(30, 30, 30, 0.8)' // Dark transparent background for dark mode
                    : 'rgba(255, 255, 255, 0.2)', // Slightly transparent background for light mode
                  backdropFilter: darkMode ? 'blur(8px)' : 'blur(5px)', // Increased blur for dark mode
                  borderRadius: '12px',
                  boxShadow: darkMode
                    ? '0 8px 16px rgba(0, 0, 0, 0.5)' // Darker shadow for dark mode
                    : '0 8px 16px rgba(0, 0, 0, 0.15)', // Softer shadow for light mode
                  transition: 'all 0.3s ease',
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '15px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1.1rem',
                        fontFamily: 'Poppins, sans-serif',
                        color: darkMode ? '#e0e0e0' : '#333', // Light text for dark mode
                      }}
                    >
                      Filters
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    
                      {isMobile && expanded && (
                        <Tooltip title="Reset Filters">
                          <IconButton
                            aria-label="reset filters"
                            onClick={handleResetFilters}
                            sx={{ mr: 1 }}
                          >
                            <RestartAlt sx={{ color: '#0056b3' }} />
                          </IconButton>
                        </Tooltip>
                      )}

                    
                      <IconButton
                        aria-expanded={expanded}
                        aria-label="show more"
                        onClick={handleExpandClick}
                      >
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Divider sx={{ borderColor: darkMode ? '#555' : '#ddd' }} />

                  <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: darkMode ? '#e0e0e0' : '#444' }}>
                        Difficulty
                      </Typography>
                      <Select
                        value={selectedDifficulty}
                        onChange={(e) => {
                          const value = e.target.value;
                          setSelectedDifficulty(value);
                          handleSortByDifficulty(value);
                        }}
                        fullWidth
                        sx={{
                          mt: 1,
                          backgroundColor: darkMode ? '#333' : '#f0f0f0',
                          borderRadius: '6px',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Easy">Easy</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="Hard">Hard</MenuItem>
                      </Select>
                    </Box>

                    <Divider sx={{ mt: 2, borderColor: darkMode ? '#555' : '#ddd' }} />

                    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sortByTutorial}
                              onChange={handleSortByTutorialAvailability}
                              sx={{ color: darkMode ? '#76c7c0' : '#1e2459' }}
                            />
                          }
                          label="Tutorial Available"
                          sx={{ fontFamily: 'Poppins, sans-serif', color: darkMode ? '#e0e0e0' : '#000' }}
                        />
                      </FormGroup>
                    </Box>

                    <Divider sx={{ mt: 2, borderColor: darkMode ? '#555' : '#ddd' }} />

            
                    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: darkMode ? '#e0e0e0' : '#444' }}>
                        Sort By
                      </Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={sortBySimilarityScore}
                              onChange={() => {
                                setSortBySimilarityScore(true);
                                setSortByAcceptanceRate(false);
                                setSortByPopularity(false);
                                handleSortBySimilarityScore();
                              }}
                              sx={{ color: darkMode ? '#76c7c0' : '#1e2459' }}
                            />
                          }
                          label="Similarity Score"
                          sx={{ fontFamily: 'Poppins, sans-serif', color: darkMode ? '#e0e0e0' : '#000' }}
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={sortByAcceptanceRate}
                              onChange={() => {
                                setSortBySimilarityScore(false);
                                setSortByAcceptanceRate(true);
                                setSortByPopularity(false);
                                handleSortByAcceptanceRate();
                              }}
                              sx={{ color: darkMode ? '#76c7c0' : '#1e2459' }}
                            />
                          }
                          label="Acceptance Rate"
                          sx={{ fontFamily: 'Poppins, sans-serif', color: darkMode ? '#e0e0e0' : '#000' }}
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={sortByPopularity}
                              onChange={() => {
                                setSortBySimilarityScore(false);
                                setSortByAcceptanceRate(false);
                                setSortByPopularity(true);
                                handleSortByPopularity();
                              }}
                              sx={{ color: darkMode ? '#76c7c0' : '#1e2459' }}
                            />
                          }
                          label="Popularity"
                          sx={{ fontFamily: 'Poppins, sans-serif', color: darkMode ? '#e0e0e0' : '#000' }}
                        />
                      </FormGroup>
                    </Box>

                    {!isMobile && <Divider sx={{ mt: 2, borderColor: darkMode ? '#555' : '#ddd' }} />}

                    {!isMobile && 
                      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                        <Button
                          sx={{
                            borderRadius: '30px',
                            height: '50px',
                            width: '100%',
                            padding: '0 20px',
                            fontSize: '16px',
                            boxShadow: 'none',
                            backgroundColor: darkMode ? '#082680' : '#1e2459',
                            color: 'white',
                            marginTop: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                              backgroundColor: darkMode ? '#34495e' : '#0056b3', // Lighter shade of blue for hover in dark mode
                            },
                            '&:disabled': {
                              backgroundColor: '#ccc',
                              color: '#777',
                            },
                          }}
                          onClick={handleResetFilters}
                        >
                          <RestartAlt sx={{ marginRight: '8px' }} />
                          Reset
                        </Button>
                    </Box>
                    }
                  </CardContent>
                </Collapse>
              </Card>
            </Box>

            <Box sx={{ flexBasis: isMobile ? '100%' : '70%', mb: 3, position: 'relative', minHeight: '200px' }}>
              {similarProblems.length < 1 && (
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    letterSpacing: '0.05em',
                    color: darkMode ? "#fff" : ''
                  }}
                >
                  No Data Available :(
                </Typography>
              )}
              
              <Grid2 container rowGap={1} columnGap={1} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                {similarProblems.slice(0, visibleCount).map((problem) => (
                  <Grid2 item xs={12} md={6} lg={4} key={problem.problem_id} sx={{ display: 'flex', flexGrow: 1 }}>
                    <SimilarQuestionCard
                      questionId={problem.problem_id}
                      title={problem.title}
                      similarityScore={problem.score}
                      url={problem.url}
                      difficulty={problem.difficulty}

                      onClick={() => navigate(`/problems/${problem.problem_id}`)}
                    />
                  </Grid2>
                ))}
              </Grid2>


              {visibleCount < similarProblems.length && (
                <Box sx={{ marginTop: 2 }}>
                  <Button
                    variant="contained"
                    sx={{ width: '100%', backgroundColor: darkMode ? '#082680' : '#1e2459', color: '#fff'}}
                    onClick={handleLoadMore}
                  >
                    Load More
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
      </Container>
      }
      {!isLoading && <Footer/>}
    </Box>
  );
};

export default LcProblemDetails;
