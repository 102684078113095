import { Container, Typography, Box, IconButton, Link, Card } from "@mui/material";
import { Email, Telegram } from "@mui/icons-material"; // Assuming you've imported these icons
import AppAppBar from "../components/AppAppBar";
import Footer from "../components/Footer";
import { useContext } from "react";
import { ThemeContext } from '../ThemeContext';


function SessionBook() {
    const { darkMode } = useContext(ThemeContext);
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: darkMode ? 'grey.900' : 'white' }}>
            <AppAppBar/>

          
            <Container
                maxWidth="sm"
                sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                }}
            >
                <Card
                    sx={{
                        padding: 2,
                        textAlign: 'center',
                        backgroundColor: 'white',
                        borderRadius: 2,
                        boxShadow: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: darkMode
                            ? '0 8px 16px rgba(0, 0, 0, 0.6)' // More prominent shadow for dark mode
                            : '0 4px 8px rgba(0, 123, 255, 0.2)', 
                        borderRadius: 2,
                        backgroundColor: darkMode 
                            ? 'rgba(30, 30, 30, 0.8)' // Dark transparent background for dark mode
                            : 'rgba(255, 255, 255, 0.2)',
                        
                    }}
                >
                    <Typography 
                        variant="h5" // Adjusted title font size
                        gutterBottom 
                        sx={{ fontFamily: 'Fredoka One', fontSize: '1.75rem' }} // Custom font and smaller size
                    >
                        Book a Session with Me
                    </Typography>
                    
                    <Typography 
                        variant="body1" 
                        sx={{ fontFamily: 'Fredoka One', fontSize: '1rem' }} // Applying custom font
                    >
                        Whether you need assistance with programming assignments, projects, specific topics, Leetcode problems, or understanding data structures and algorithms, I am here to help you overcome your challenges.
                    </Typography>
                    
                    <Typography 
                        variant="h6" 
                        color="primary" 
                        sx={{ fontFamily: 'Fredoka One' }} // Applying custom font
                    >
                        Rate: $10/hr
                    </Typography>
                    
                    <Typography 
                    variant="body2" 
                    sx={{ fontFamily: 'Fredoka One', fontSize: '0.875rem' }} // Applying custom font and size
                    >
                    Feel free to reach out to me through any of the platforms below to schedule a session.
                    </Typography>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <IconButton 
                        component={Link} 
                        href="mailto:markagugo@gmail.com" 
                        sx={{ color: '#880808' }} // Email icon with red color
                    >
                        <Email />
                    </IconButton>
                    <IconButton
                        component={Link} 
                        href="https://discord.com/users/macdonaldd_/"
                    >
                        <img
                        src="https://img.icons8.com/?size=100&id=30998&format=png&color=000000"
                        alt="icon"
                        style={{ width: '32px', height: '32px' }}
                        />
                    </IconButton>
                    
                    <IconButton 
                        component={Link} 
                        href="https://t.me/markagugo" 
                        sx={{ color: 'darkblue' }} // Telegram icon with dark blue color
                    >
                        <Telegram />
                    </IconButton>
                    </Box>
                </Card>
            </Container>

            <Footer />
        </Box>
    );
}

export default SessionBook;
