import React, { useContext } from 'react';
import { Box, Grid2, Typography, useTheme, useMediaQuery, Card } from '@mui/material';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import { ThemeContext } from '../ThemeContext';
import { useNavigate } from 'react-router-dom';

const Problems = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { darkMode } = useContext(ThemeContext);

  const problems = [
    'Array',
    'Linked List',
    'Two Pointers',
    'Sorting',
    'String',
    'Counting Sort',
    'Radix Sort',
    'Binary Search',
    'Prefix Sum',
    'Stack',
    'Greedy',
    'Design',
    'Depth-First Search',
    'Ordered Set',
    'Graph',
    'Reservoir Sampling',
    'Game Theory',
    'Tree',
    'Binary Search Tree',
    'Bit Manipulation',
    'Simulation',
    'Shortest Path',
    'Rejection Sampling',
    'Eulerian Circuit',
    'Backtracking',
    'Database',
    'Heap (Priority Queue)',
    'Recursion',
    'Queue',
    'Number Theory',
    'Math',
    'Segment Tree',
    'Breadth-First Search',
    'Biconnected Component',
    'Enumeration',
    'Divide and Conquer',
    'Hash Table',
    'Monotonic Queue',
    'Brainteaser',
    'Hash Function',
    'Data Stream',
    'Trie',
    'Matrix',
    'Line Sweep',
    'Counting',
    'Doubly-Linked List',
    'Sliding Window',
    'Bucket Sort',
    'Interactive',
    'Strongly Connected Component',
    'Topological Sort',
    'Binary Tree',
    'Probability and Statistics',
    'Dynamic Programming',
    'Bitmask',
    'Concurrency',
    'Randomized',
    'Union Find',
    'Suffix Array',
    'Monotonic Stack',
    'Merge Sort',
    'Geometry',
    'Iterator',
    'Binary Indexed Tree',
    'Minimum Spanning Tree',
    'Quickselect',
    'Memoization',
    'Rolling Hash',
    'String Matching',
    'Shell',
    'Combinatorics'
  ];

  const formatProblemName = (problem) => {
    return problem.toLowerCase().replace(/\s+/g, '-').trim();
  };

  const handleCardClick = (problem) => {
    const formattedProblem = formatProblemName(problem);
    const url = `/tag/${formattedProblem}`;
    navigate(url);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: darkMode ? 'grey.900' : 'white',
      }}
    >
      <AppAppBar />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 2,
          flexGrow:1,
          marginTop: 7
        }}
      >
        {isMobile && 
          <Grid2 container spacing={1} sx={{ justifyContent: 'center' }}>
            {problems.map((problem, index) => (
              <Grid2 item key={index}>
                <Typography
                  onClick={() => handleCardClick(problem)}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: darkMode 
                      ? index % 2 === 0 
                        ? '#333'  // Darker background for alternating items
                        : '#444'  // Slightly lighter background for alternating items
                      : index % 2 === 0 
                        ? '#e8f0fe' 
                        : '#f1f8e9',
                    padding: 1,
                    borderRadius: '12px',
                    fontSize:'0.75rem',
                    color: darkMode ? '#fff' : '#000', // Text color for dark mode
                    textAlign: 'center',
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: darkMode ? '#555' : '#c5cae9',  // Darker hover for dark mode
                    },
                  }}
                >
                  {problem}
                </Typography>
              </Grid2>
            ))}
          </Grid2>
        }

        {!isMobile && (
          <Grid2 
            container 
            sx={{ 
              width: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center' 
            }}
          >
            {problems.map((problem, index) => (
              <Grid2 item xs={12} key={index}>
                <Card 
                  sx={{ 
                    width: '100vh',
                    cursor: 'pointer',
                    backgroundColor: darkMode 
                      ? index % 2 === 0 
                        ? '#333'  // Darker background for alternating items
                        : ''  // Slightly lighter background for alternating items
                      : index % 2 === 0 
                        ? '#f5f5f5' 
                        : 'transparent',
                    color: darkMode ? '#fff' : '#000', // Text color for dark mode
                    padding: 1,
                    margin: 0,
                    boxShadow: 'none',
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '16px',
                    '&:hover': {
                      backgroundColor: darkMode ? '#555' : '#ddd',  // Darker hover effect for dark mode
                    },
                  }}
                  onClick={() => handleCardClick(problem)}
                >
                  <Typography>{problem}</Typography>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        )}


      </Box>
      <Footer/>
    </Box>
  );
};

export default Problems;
