import supabase from '../supabase';
import { Backdrop, Box, Button, Card, CardContent, Checkbox, CircularProgress, Collapse, Container, Divider, FormControlLabel, FormGroup, Grid2, IconButton, MenuItem, Radio, Select, Tooltip, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, RestartAlt } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import ProblemsCard from '../components/problems-card';
import { ThemeContext } from "../ThemeContext";
import { useNavigate, useParams } from 'react-router-dom';


function Tag() {
  const { darkMode } = useContext(ThemeContext);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { tag } = useParams();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState('All'); 
  const [sortByTutorial, setSortByTutorial] = useState(false);
  const [sortByAcceptanceRate, setSortByAcceptanceRate] = useState(false);
  const [sortByPopularity, setSortByPopularity] = useState(false);
  const [problems, setProblems] = useState([]);
  const [problemsCopy, setProblemsCopy] = useState([]);
  const [visibleCount, setVisibleCount] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [fetchingData, setFetchingData] = useState(true);
  const [offset, setOffset] = useState(0);
  const ITEMS_PER_BATCH = 1000;
  const VISIBLE_INCREMENT = 100;

  const handleExpandClick = () => setExpanded(!expanded);

  const fetchProblems = async () => {
    setFetchingData(true);
    const { data: problemsData, error: fetchError } = await supabase
      .from('problems')
      .select("id, problem_id, title, topics, url, difficulty, acceptance_rate, discussions, likes, tutorial")
      .ilike('tags', `%${tag}%`)
      .range(offset, offset + ITEMS_PER_BATCH - 1);

    if (isLoading){
      setIsLoading(false);
    };

    if (problemsData.length < 1000){
      setHasMoreData(false);
    };
    
    setProblems(prevProblems => [...prevProblems, ...problemsData]);
    setProblemsCopy(prevProblemsCopy => [...prevProblemsCopy, ...problemsData]);

    setOffset(offset + ITEMS_PER_BATCH - 1)
    setVisibleCount(visibleCount + VISIBLE_INCREMENT);
    setFetchingData(false);

  };

  useEffect(() => {
    fetchProblems();
  }, [tag]);

  const handleLoadMore = () => {
    if (visibleCount < problems.length) {
      setVisibleCount(visibleCount + VISIBLE_INCREMENT);
    } else if (hasMoreData) {
      fetchProblems(); 
    }
  };

  const parseNumber = (value) => {
    let num = parseFloat(value);
    if (value.includes('K')) return num * 1000;
    if (value.includes('M')) return num * 1000000;
    if (value.includes('B')) return num * 1000000000;
    return num;
  };

  const handleResetFilters = () => {
    setSelectedDifficulty('All');
    setSortByAcceptanceRate(false);
    setSortByPopularity(false);
    setSortByTutorial(false);
    setProblems(problemsCopy); 
  };

  const handleSortByDifficulty = (difficulty) => {
    setSelectedDifficulty(difficulty);
    if (difficulty === 'All') {
      setProblems(problemsCopy);
    } else {
      const filteredProblems = problemsCopy.filter(problem => problem.difficulty === difficulty);
      setProblems(filteredProblems);
    }
  };

  const handleSortByAcceptanceRate = () => {
    const sortedProblems = [...problems].sort((a, b) => parseNumber(b.acceptance_rate) - parseNumber(a.acceptance_rate));
    setProblems(sortedProblems);
  };

  const handleSortByPopularity = () => {
    const sortedProblems = [...problems].sort((a, b) => 
      (parseNumber(b.discussions) + parseNumber(b.likes)) - 
      (parseNumber(a.discussions) + parseNumber(a.likes))
    );
    setProblems(sortedProblems);
  };


  const handleSortByTutorialAvailability = () => {
    setSortByTutorial(prevState => {
      if (!prevState) {
        const filteredProblems = problems.filter(problem => problem.tutorial !== null);
        setProblems(filteredProblems);
      } else {
        setProblems(problemsCopy);
      }
      return !prevState;
    });
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: darkMode ? 'grey.900' : '',
      }}
    >
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading || fetchingData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppAppBar/>

      {!isLoading &&
        <Container sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 5 }} gap={2}>

          <Box sx={{ marginTop: 3 }}>
      <Card
        sx={{
          margin: 1,
          backgroundColor: darkMode 
            ? 'rgba(30, 30, 30, 0.8)' // Dark transparent background for dark mode
            : 'rgba(255, 255, 255, 0.2)', // Slightly transparent background for light mode
          backdropFilter: darkMode ? 'blur(8px)' : 'blur(5px)', // Increased blur for dark mode
          borderRadius: '12px',
          boxShadow: darkMode
            ? '0 8px 16px rgba(0, 0, 0, 0.5)' // Darker shadow for dark mode
            : '0 8px 16px rgba(0, 0, 0, 0.15)', // Softer shadow for light mode
          transition: 'all 0.3s ease',
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '15px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: '1.1rem',
                fontFamily: 'Poppins, sans-serif',
                color: darkMode ? '#e0e0e0' : '#333', // Light text for dark mode
              }}
            >
              Filters
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Reset Button with Tooltip */}
              {expanded && (
                <Tooltip title="Reset Filters">
                  <IconButton
                    aria-label="reset filters"
                    onClick={handleResetFilters}
                    sx={{ mr: 1 }}
                  >
                    <RestartAlt sx={{ color: darkMode ? '#76c7c0' : '#0056b3' }} />
                  </IconButton>
                </Tooltip>
              )}

              {/* Expand/Collapse Button */}
              <IconButton
                aria-expanded={expanded}
                aria-label="show more"
                onClick={handleExpandClick}
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          </Box>
        </CardContent>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider sx={{ borderColor: darkMode ? '#555' : '#ddd' }} />

          {/* Layout inside the card with two columns on desktop, stacked on mobile */}
          <CardContent sx={{ paddingLeft: isMobile ? 0 : '', paddingRight: isMobile ? 0 : '' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row', // Adjusts layout based on screen size
                gap: 2,
              }}
            >
              {/* Left column: Difficulty and Tutorial */}
              <Box sx={{ flex: 1 }}>
                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: darkMode ? '#e0e0e0' : '#444' }}>
                    Difficulty
                  </Typography>
                  <Select
                    value={selectedDifficulty}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedDifficulty(value);
                      handleSortByDifficulty(value);
                    }}
                    fullWidth
                    sx={{
                      mt: 1,
                      backgroundColor: darkMode ? '#333' : '#f0f0f0',
                      borderRadius: '6px',
                      fontFamily: 'Poppins, sans-serif',
                      color: darkMode ? '#e0e0e0' : '#000',
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Easy">Easy</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Hard">Hard</MenuItem>
                  </Select>
                </Box>

                <Divider sx={{ mt: 2, borderColor: darkMode ? '#555' : '#ddd' }} />

                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sortByTutorial}
                          onChange={handleSortByTutorialAvailability}
                          sx={{ color: darkMode ? '#76c7c0' : '#1e2459' }}
                        />
                      }
                      label="Tutorial Available"
                      sx={{ fontFamily: 'Poppins, sans-serif', color: darkMode ? '#e0e0e0' : '#000' }}
                    />
                  </FormGroup>
                </Box>
              </Box>

              {/* Vertical divider for desktop view */}
              {isMobile ? <Divider sx={{ borderColor: darkMode ? '#555' : '#ddd' }} orientation='horizontal' /> : <Divider orientation="vertical" flexItem sx={{ mx: 2, borderColor: darkMode ? '#555' : '#ddd' }} />}

              {/* Right column: Sorter */}
              <Box sx={{ flex: 1 }}>
                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: darkMode ? '#e0e0e0' : '#444' }}>
                    Sort By
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={sortByAcceptanceRate}
                          onChange={() => {
                            setSortByAcceptanceRate(true);
                            setSortByPopularity(false);
                            handleSortByAcceptanceRate();
                          }}
                          sx={{ color: darkMode ? '#76c7c0' : '#1e2459' }}
                        />
                      }
                      label="Acceptance Rate"
                      sx={{ fontFamily: 'Poppins, sans-serif', color: darkMode ? '#e0e0e0' : '#000' }}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={sortByPopularity}
                          onChange={() => {
                            setSortByAcceptanceRate(false);
                            setSortByPopularity(true);
                            handleSortByPopularity();
                          }}
                          sx={{ color: darkMode ? '#76c7c0' : '#1e2459' }}
                        />
                      }
                      label="Popularity"
                      sx={{ fontFamily: 'Poppins, sans-serif', color: darkMode ? '#e0e0e0' : '#000' }}
                    />
                  </FormGroup>
                </Box>
              </Box>
            </Box>

            {/* Centered Reset Button (Optional) */}
            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'flex-start' : 'center',
                paddingLeft: isMobile ? 2 : 0,
                paddingRight: isMobile ? 2 : 0,
                marginTop: 2,
              }}
            >
              <Button
                sx={{
                  borderRadius: '30px',
                  height: '50px',
                  width: isMobile ? '100%' : '50%', // Adjust button width based on screen size
                  padding: '0 20px',
                  fontSize: '16px',
                  boxShadow: 'none',
                  backgroundColor: darkMode ? '#333' : '#1e2459',
                  color: darkMode ? '#e0e0e0' : 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: darkMode ? '#444' : '#0056b3',
                  },
                  '&:disabled': {
                    backgroundColor: darkMode ? '#555' : '#ccc',
                    color: darkMode ? '#888' : '#777',
                  },
                }}
                onClick={handleResetFilters}
              >
                <RestartAlt sx={{ marginRight: '8px' }} />
                Reset Filters
              </Button>
            </Box> */}
          </CardContent>
        </Collapse>
      </Card>
    </Box>

            {/* Problems List Section */}
            <Box sx={{ mb: 3 }}>

              {problems.length < 1 && (
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      textAlign: 'center',
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      letterSpacing: '0.05em',
                      color: darkMode ? "#fff" : ''
                    }}
                  >
                    No Data Available :(
                  </Typography>
                )}
              
              <Grid2 container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                {problems.slice(0, visibleCount).map((problem) => (
                  <Grid2 item xs={12} md={6} lg={4} key={problem.problem_id} sx={{ display: 'flex', flexGrow: 1 }}>
                    <ProblemsCard
                      questionId={problem.problem_id}
                      title={problem.title}
                      url={problem.url}
                      difficulty={problem.difficulty}
                      onClick={() => navigate(`/problems/${problem.problem_id}`)}
                    />
                  </Grid2>
                ))}
              </Grid2>

              

                {problems.length > 0 && (visibleCount < problems.length || hasMoreData) && (
                  <Box sx={{ marginTop: 2 }}>
                    <Button
                      variant="contained"
                      sx={{ width: '100%', backgroundColor: darkMode ? '#082680' : '#1e2459', }}
                      onClick={handleLoadMore}
                    >
                      Load More
                    </Button>
                  </Box>
                )}

              
            </Box>
          </Box>
        </Container>
      }
      {!isLoading && <Footer/>}
    </Box>
  );
}

export default Tag;
