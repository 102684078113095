'use client';

import { Box, Card, Typography, IconButton, Link, Divider, useMediaQuery } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite"; // For heart emoji
import { InstallDesktop } from "@mui/icons-material";
import { useContext } from "react";
import { ThemeContext } from "../ThemeContext";


function Footer() {
  const { darkMode } = useContext(ThemeContext);
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        bgcolor: darkMode ? 'grey.900' : '#1e2459',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        cursor: 'pointer'
      }}
    >
      {darkMode && <Divider 
        sx={{ 
          width: isMobile ? "100%" : "70%",
          margin: 2,
          borderColor: "#fff"
        }} 
        orientation="horizontal"/>
      }


       
      {/* Join our Discord Card */}
     
      <Card
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)', // Transparent background
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle box shadow
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          width: {xs: "100%", md: '60%'},
          marginBottom: '16px', // Space between card and text
        }}
        onClick={() => window.open("https://discord.gg/f7ua7DBjxw", "_blank")}
      >
    
      <IconButton>
        <img 
          src="https://img.icons8.com/?size=100&id=30998&format=png&color=000000" 
          alt="icon"
          style={{
            width: '32px',
            height: '32px',
            marginRight: '8px',
          }}
        />
      </IconButton>

        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          Join Study Discord
        </Typography>
      </Card>

      {/* Built with love by Mark */}
      <Typography
        variant="body1"
        sx={{ 
          color: '#fff', 
          marginBottom: '8px',
        }}
      >
        Built with <FavoriteIcon sx={{ color: 'red', fontSize: '18px' }} /> by{' '}
        <Link href="https://markagugo.github.io/" target="_blank" passHref>
          <Typography
            component="span"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: '#fff',
            }}
          >
            Mark
          </Typography>
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer;
