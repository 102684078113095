'use client';

import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

export default function Toast({ msg, onClose }) {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
            onClose={handleClose}
            severity="warning"
            variant="filled"
            sx={{ width: '100%' }}
        >
            {msg}
        </Alert>
    </Snackbar>
  );
}
