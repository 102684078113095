import { useState, useEffect, useContext } from 'react';
import { Backdrop, Box, Button, CircularProgress, Container, Typography, useMediaQuery } from '@mui/material';
import { ThemeContext } from '../ThemeContext';
import AppAppBar from '../components/AppAppBar';
import supabase from '../supabase';
import { useParams } from 'react-router-dom';
import MarkDownRender from '../components/MarkDownRender';
import Footer from '../components/Footer';

function Article(){
    const { darkMode } = useContext(ThemeContext);
    const isMobile = useMediaQuery('(max-width:600px)');
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        async function fetchData() {
            try {
                const { data: courseData, error: fetchError } = await supabase
                    .from('dsa')
                    .select("data")
                    .eq("title", id)
                    .single();
            
                if (fetchError) {
                    console.error("Error fetching problem data:", fetchError);
                    return;
                }

                setData(courseData.data);
                setCurrentIndex(0);
            } catch (error) {
                
            }
        }
        fetchData();
    }, [id]);

    const handleNext = () => {
        if (currentIndex < (data.length - 1)) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: darkMode ? 'grey.900' : 'white' }}>
            <AppAppBar />
            
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={!data}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {data && (
                <>
                  <MarkDownRender url={data[currentIndex]} />
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    mt: 2, 
                    mb: 2, 
                    width: '100%' 
                  }}>
                    {/* Left side container for Previous button */}
                    <Box>
                      {currentIndex > 0 && (
                        <Button
                          onClick={handlePrevious}
                          variant="contained"
                          color="primary"
                        >
                          Previous
                        </Button>
                      )}
                    </Box>

                    {/* Right side container for Next button */}
                    <Box>
                      {currentIndex < data.length - 1 && (
                        <Button
                          onClick={handleNext}
                          variant="contained"
                          color="primary"
                        >
                          Next
                        </Button>
                      )}
                    </Box>
                  </Box>
                </>
              )}
            </Container>

            <Footer/>
        </Box>
    );
}

export default Article;
