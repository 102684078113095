import React, { useContext } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Chip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from "../ThemeContext";


const ProblemsCard = ({ questionId, title, url, difficulty, onClick }) => {
  const { darkMode } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Difficulty indicator colors
  const getDifficultyColor = (difficulty) => {
    switch (difficulty) {
      case 'Easy':
        return 'success'; // Green for easy
      case 'Medium':
        return 'warning'; // Yellow for medium
      case 'Hard':
        return 'error'; // Red for hard
      default:
        return 'default';
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: darkMode
          ? 'rgba(30, 30, 30, 0.8)' // Matching dark mode background
          : 'rgba(255, 255, 255, 0.2)', // Light mode background
        borderRadius: '12px',
        boxShadow: darkMode
          ? '0 8px 16px rgba(0, 0, 0, 0.5)' // Darker shadow for dark mode
          : '0 8px 16px rgba(0, 0, 0, 0.1)', // Softer shadow for light mode
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: darkMode ? '0 12px 24px rgba(0, 0, 0, 0.7)' : '0 12px 24px rgba(0, 0, 0, 0.2)', // Elevated shadow on hover
        },
      }}
      onClick={onClick} // Add onClick handler
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Question ID and Title */}
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'normal',
              fontFamily: 'Poppins, sans-serif',
              fontSize: isMobile ? '0.85rem' : '0.9rem',
              color: darkMode ? '#e0e0e0' : '#333', // Light text for dark mode
              marginBottom: '0',
              flex: 1,
            }}
            noWrap
          >
            {questionId}. {title}
          </Typography>

          {/* Difficulty Chip */}
          <Chip
            label={difficulty}
            color={getDifficultyColor(difficulty)}
            size="small"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '0.65rem',
              borderRadius: '6px',
              fontWeight: 'bold',
              height: '20px',
              marginLeft: '8px',
              color: "#fff"
            }}
          />

          {/* External Link Icon */}
          {url && (
            <IconButton
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                window.open(url, '_blank')
              }}
              sx={{
                color: darkMode ? '#76c7c0' : '#007bff',
                marginLeft: '8px',
              }}
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProblemsCard;