import React, { useContext } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Chip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from "../ThemeContext";

const SimilarQuestionCard = ({ questionId, title, similarityScore, url, difficulty, onClick }) => {
  const { darkMode } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Difficulty indicator colors
  const getDifficultyColor = (difficulty) => {
    switch (difficulty) {
      case 'Easy':
        return 'success'; // Green for easy
      case 'Medium':
        return 'warning'; // Yellow for medium
      case 'Hard':
        return 'error'; // Red for hard
      default:
        return 'default';
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: darkMode
          ? 'rgba(30, 30, 30, 0.8)' // Matching dark mode background
          : 'rgba(255, 255, 255, 0.2)', // Light mode background
        borderRadius: '12px',
        boxShadow: darkMode
          ? '0 8px 16px rgba(0, 0, 0, 0.5)' // Darker shadow for dark mode
          : '0 8px 16px rgba(0, 0, 0, 0.1)', // Softer shadow for light mode
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: darkMode ? '0 12px 24px rgba(0, 0, 0, 0.7)' : '0 12px 24px rgba(0, 0, 0, 0.2)', // Elevated shadow on hover
        },
      }}
       onClick={onClick} // Add onClick handler
    >
      <CardContent>
        {/* Question ID and Title */}
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'normal', // No bold
            fontFamily: 'Poppins, sans-serif', // Clean, modern font
            fontSize: isMobile ? '0.95rem' : '1rem', // Slightly smaller on mobile
            color: darkMode ? '#e0e0e0' : '#333', // Dark text for readability
            marginBottom: isMobile ? '8px' : '12px',
          }}
          noWrap
        >
          {questionId}. {title}
        </Typography>

        {/* Difficulty Chip */}
        <Chip
          label={difficulty}
          color={getDifficultyColor(difficulty)}
          size="small"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.75rem',
            borderRadius: '6px',
            fontWeight: 'bold',
            height: '22px',
            marginRight: '10px',
          }}
        />

        {/* Similarity Score and External Link */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: isMobile ? '8px' : '12px',
          }}
        >
          {/* Similarity Score */}
          {similarityScore !== null && similarityScore !== undefined && (
            <Typography
              variant="body2"
              sx={{
                color: darkMode ? '#e0e0e0' : '#666',
                fontFamily: 'Poppins, sans-serif',
                fontSize: isMobile ? '0.85rem' : '0.9rem',
              }}
            >
              {similarityScore.toFixed(2)}%
            </Typography>
          )}

          {/* External Link Icon */}
          <IconButton
            size="small"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              window.open(url, '_blank');
            }}
            sx={{
              marginLeft: 'auto', // Push to the end of the row
              color: '#007bff',
            }}
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SimilarQuestionCard;
