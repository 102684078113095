import React, { useEffect, useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeRaw from 'rehype-raw';
import { Box, Container } from '@mui/material';
import { ThemeContext } from '../ThemeContext';

const MarkDownRender = ({ url }) => {
  const [content, setContent] = useState('');
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    fetch(url)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((err) => console.error('Failed to fetch markdown file:', err));
  }, [url]);

 
  const markdownStyle = {
    color: darkMode ? '#f5f5f5' : '#333',
    backgroundColor: darkMode ? '' : '#fff',
    paddingY: 8,
    borderRadius: '8px',
    lineHeight: '1.6',
    transition: 'background-color 0.3s, color 0.3s',
  };

  return (
    <Container sx={markdownStyle}>
      <ReactMarkdown
        children={content}
        rehypePlugins={[rehypeRaw]}
        components={{
         
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <SyntaxHighlighter
                style={materialDark}
                language={match[1]}
                PreTag="div"
                {...props}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code
                className={className}
                style={{
                  backgroundColor: darkMode ? '#333' : '#f5f5f5',
                  color: darkMode ? '#f5f5f5' : '#333',
                  padding: '2px 4px',
                  borderRadius: '4px',
                }}
                {...props}
              >
                {children}
              </code>
            );
          },
         
          img({ src, alt }) {
            return (
              <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <img src={src} alt={alt} style={{ maxWidth: '100%', height: 'auto' }} />
              </div>
            );
          },
         
          iframe({ src, width, height, allowFullScreen }) {
            return (
              <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <iframe
                  src={src}
                  width={width || '100%'}
                  height={height || '400px'}
                  allowFullScreen={allowFullScreen}
                  style={{ border: 'none' }}
                />
              </div>
            );
          },
          video({ src, width, height, controls }) {
            return (
              <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <video width={width || '100%'} height={height || '400px'} controls>
                  <source src={src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          },
        }}
      />
    </Container>
  );
};

export default MarkDownRender;
