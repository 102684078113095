import { Box, Container, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import AppAppBar from "../components/AppAppBar";
import Footer from "../components/Footer";
import { ThemeContext } from "../ThemeContext";
import MarkDownRender from "../components/MarkDownRender";

function BigO(){
    const { darkMode } = useContext(ThemeContext);
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: darkMode ? 'grey.900' : 'white' }}>
            <AppAppBar/>

            <Container sx={{ flexGrow: 1 }}>
                <MarkDownRender url={isMobile ? "https://leetprep.io/courses/big-o-notation/index-mobile.md" : "https://leetprep.io/courses/big-o-notation/index-desktop.md"}/>
            </Container>

            <Footer/>
        </Box>
    );
}

export default BigO;