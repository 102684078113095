import { Box } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import { ThemeContextProvider } from "./ThemeContext";
import Problems from "./pages/problems";
import Tag from "./pages/tag";
import LcProblemDetails from "./pages/lc-problem-details";
import SessionBook from "./pages/book-session";
import NewsLetter from "./pages/newsletter";
import BigO from "./pages/big-o-notation";
import DataStructures from "./pages/data-structures";
import Algorithms from "./pages/algorithms";
import Article from "./pages/article";

function App() {
  return (
    <Box>
      <ThemeContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/problems" element={<Problems/>}/>
            <Route path="/tag/:tag" element={<Tag/>}/>
            <Route path="/problems/:id" element={<LcProblemDetails/>}/>
            <Route path="/book-session" element={<SessionBook/>}/>
            <Route path="/newsletter/" element={<NewsLetter/>}/>
            <Route path="/big-o-notation/" element={<BigO/>}/>
            <Route path="/data-structures/" element={<DataStructures/>}/>
            <Route path="/algorithms/" element={<Algorithms/>}/>
            <Route path="/data-structures/:id" element={<Article/>}/>
            <Route path="/algorithms/:id" element={<Article/>}/>
          </Routes>
        </BrowserRouter>
      </ThemeContextProvider>
    </Box>
  );
}

export default App;
