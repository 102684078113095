import React, { useContext, useState } from "react";
import { Container, Typography, Box, Button, TextField, Snackbar } from "@mui/material";
import { ThemeContext } from '../ThemeContext';
import { database } from '../firebase';
import { ref, set } from "firebase/database";
import AppAppBar from "../components/AppAppBar";
import Footer from "../components/Footer";

function SessionBook() {
    const { darkMode } = useContext(ThemeContext);
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        if (email) {
            const randomKey = Date.now().toString(36) + Math.random().toString(36).substring(2);
            
            try {
               
                await set(ref(database, `subscribed_emails/${randomKey}`), { email });
                setMessage(`Thank you! You will be notified at ${email}.`);
            } catch (error) {
                console.error('Error saving email:', error);
                setMessage('Failed to subscribe. Please try again later.');
            }
            
            setOpen(true);
            setEmail('');
        } else {
            setMessage('Please enter a valid email address.');
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: darkMode ? 'grey.900' : 'white' }}>
            <AppAppBar/>

            <Container
                maxWidth="sm"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography 
                    variant="h4" 
                    gutterBottom 
                    sx={{ fontFamily: 'Fredoka One',  color: darkMode ? '#e0e0e0' : '#333' }}
                >
                    Stay Notified!
                </Typography>
                <Typography 
                    variant="body1" 
                    sx={{ fontFamily: 'Fredoka One', marginBottom: 2, color: darkMode ? '#e0e0e0' : '#333'}}
                >
                    Enter your email to receive notifications for new courses and topics.
                    Additionally, you could also join the Discord channel.
                </Typography>

                <Box 
                    component="form" 
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                    }}
                >
                    <TextField
                        label="Email Address"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            input: { bgcolor: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'white', color: darkMode ? 'white' : 'black' },
                            label: { color: darkMode ? 'white' : 'black' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                                },
                                '&:hover fieldset': {
                                    borderColor: darkMode ? 'white' : 'black',
                                },
                            },
                        }}
                    />
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary"
                        sx={{ bgcolor: darkMode ? '#1976d2' : '#1e2459' }}
                    >
                        Subscribe
                    </Button>
                </Box>

                <Snackbar 
                    open={open} 
                    autoHideDuration={6000} 
                    onClose={handleClose} 
                    message={message} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            </Container>

            <Footer />
        </Box>
    );
}

export default SessionBook;
